.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
}

.scrolled-header {
    background-color: #ffffff20 !important;
    backdrop-filter: blur(30px);

    .navbar-container {
        padding: 10px 0 !important;

        .icon {
            width: 90px !important;
        }
    }
}

.btn-connect {
    background-color: #283583 !important;
    min-height: 36px !important;
    color: white !important;
    padding: 0 16px !important;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: #F49400 !important;
    }
}

.navbar-container {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    transition: 0.3s all ease-in-out;

    @media screen and (max-width: 1280px) {
        padding: 10px 20px;
    }

    .icon {
        transition: 0.3s all ease-in-out;
        width: 113px;
    }

    .nav-link-box {
        display: flex;
        align-items: center;

        .nav-link {
            color: black;
            cursor: pointer;
            font-size: 16px;
            font-weight: 600;
            text-decoration: none;
            margin: 0 25px;
            padding: 0.5rem 0;
            position: relative;

            &:hover {
                color: #F49400;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: #F49400;
                transform: scaleX(0);
                transform-origin: left;
                transition: transform 0.3s ease-in-out;
            }

            &:hover::after {
                transform: scaleX(1);
            }
        }
    }

    .account-icon {
        width: 40px;
        margin-right: 10px;
        transition: 0.3s all ease-in-out;
    }

    .address {
        color: #283583;
        font-size: 16px;
        font-weight: 700;
        padding-left: 5px;
        padding-right: 20px;
    }

    .copy-icon {
        color: #283583;
        cursor: pointer;
        transition: 0.2s all ease-in-out;

        &:hover {
            color: #F49400;
        }
    }

    .btn-disconnect {
        font-size: 16px;
        font-weight: 800;
        background: #283583;
        cursor: pointer;
        transition: 0.2s all ease-in-out;
        user-select: none;

        &:hover {
            text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        }
    }

    .hamburger-react {
        color: #283583;
    }

    .menu-icon {
        display: none;
    }
}

.mobile-menu {
    .MuiList-root .MuiMenuItem-root {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px;
    }
}

@media (max-width: 800px) {
    .icon {
        width: 80px !important;
        margin-left: 15px;
    }

    .navbar-container {
        padding: 10px 0;

        .nav-link-box {
            margin-right: 20px;

            .menu-icon {
                display: block !important;
                scale: 0.6;
            }

            .list-items {
                display: none;
            }
        }
    }
}
.footer-wrapper {
    background: #FFECB3;
    padding-top: 50px;
    padding-bottom: 50px;

    .footer-container {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 900px) {
            flex-direction: column;
            align-items: center;

            .footer-logo {
                text-align: center;
            }
        }

        .link-group {
            display: flex;
            gap: 60px;

            @media screen and (max-width: 900px) {
                flex-direction: column;
                align-items: center;
                gap: 20px;
                text-align: center;
            }
        }

        .link-title {
            color: #2E7D32;
            font-size: 20px;
            font-weight: 600;
            line-height: 32px;
            margin-bottom: 15px;
        }

        a {
            margin-bottom: 10px;
        }

        .button-group {
            @media screen and (max-width: 900px) {
                margin-top: 20px;
            }
        }
    }
}
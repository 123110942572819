html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}

body * {
  font-family: "montserrat", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* For Webkit (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 3px;
  /* width of the entire scrollbar */
  height: 3px;
  /* height of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  /* color of the scroll thumb */
  border-radius: 6px;
  /* roundness of the scroll thumb */
  border: 3px solid #f1f1f1;
  /* creates padding around scroll thumb */
}

/* For Firefox */
* {
  scrollbar-width: thin;
  /* "auto" or "thin" */
  scrollbar-color: #888 #f1f1f1;
  /* thumb and track color */
}

/* Hover effect for webkit scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
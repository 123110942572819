.purchase-wrapper {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-bottom: 20px;

    .price-text {
        font-size: 20px;
        font-weight: 600;
        color: #2e7d32;
        text-transform: uppercase;
    }

    .input-group {
        max-width: 600px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 30px;
        border-radius: 16px;
        border: 1px solid #2e7d3280;
        margin: auto;
        gap: 20px;

        @media screen and (max-width: 900px) {
            gap: 10px;
            padding: 15px;

            .fs-18 {
                font-size: 14px;
                border-radius: 10px;
            }
        }

        input {
            width: 100%;
            background-color: transparent;
            border: none;
            font-size: 36px;
            outline: none;

            @media screen and (max-width: 900px) {
                font-size: 22px;
            }
        }

        .price {
            font-size: 14px;
            color: #000000b3;

            @media screen and (max-width: 900px) {
                font-size: 12px;
            }
        }

        .btn-max {
            height: 28px;
            background: #2e7d32b3;
            color: #fff;
            border-radius: 20px;
            font-size: 14px;
            box-shadow: none;
        }

        .coin-wrapper {
            width: 150px;
            justify-content: end;
        }

        img {
            width: 25px;
            height: 25px;

            @media screen and (max-width: 900px) {
                width: 16px;
                height: 16px;
            }
        }

        .coin-name {
            font-size: 24px;
            columns: black;
            font-weight: 700;
            margin-left: 5px;
            text-transform: uppercase;

            @media screen and (max-width: 900px) {
                font-size: 16px;
            }
        }
    }
}

.coin-menu {
    .MuiMenu-list {
        img {
            width: 25px;
            height: 25px;

            @media screen and (max-width: 900px) {
                width: 32px;
                height: 32px;
            }
        }

        .menu-item {
            gap: 10px;
            width: 100px;

            .coin-name {
                font-size: 20px;
                font-weight: 600;
                text-transform: uppercase;
            }
        }
    }
}